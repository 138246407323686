<template>
  <div class="userCRUD">
    <v-card v-if="formUsr || cliente.id">
      <v-card-title>
        {{ cliente.id ? "Edição Cliente" : "Cadastro de Cliente" }}
      </v-card-title>
      <v-card-text>
        <v-text-field
          v-model="cliente.name"
          :counter="255"
          label="Nome"
          required
        ></v-text-field>
        <v-text-field
          v-model="cliente.cnpj"
          :counter="255"
          label="CNPJ"
          required
        ></v-text-field>
        <v-text-field
          v-model="cliente.address"
          :counter="255"
          label="Endereço"
          required
        ></v-text-field>
        <v-text-field
          v-model="cliente.phone"
          :counter="255"
          label="Telefone"
          required
        ></v-text-field>
        <v-text-field
          v-model="cliente.veicularGroupId"
          :counter="255"
          label="Grupo dos Veiculos deste cliente no veicular"
          required
          type="number"
        ></v-text-field>

        <SelectGroup v-model="cliente.groupId" v-show="false" />

        <v-textarea
          v-model="cliente.description"
          name="input-7-1"
          :counter="255"
          label="Descrição"
        ></v-textarea>

        <v-row>
          <v-col>
            <v-checkbox
              v-model="cliente.enabled"
              label="Ativo"
              required
            ></v-checkbox>
          </v-col>
          <v-col>
            <v-text-field
              v-model.number="cliente.usersAmount"
              :counter="255"
              label="Usuários liberados para esse Cliente"
              required
              type="number"
              
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="warning" class="mr-4" @click="clearObj"
          >Limpar Campos</v-btn
        >
        <v-btn color="success" class="mr-4" @click="save(cliente)">
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
    <div class="div d-flex justify-end ma-2" v-else>
      <v-btn color="success" @click="formUsr = true">Cadastrar</v-btn>
    </div>
    <div class="tbl mt-1">
      <Table
        :items="lista"
        :headers="headers"
        :enablePicture="true"
        :rows="-1"
        @edit="editar($event)"
        @delete="delet($event)"
      />
    </div>
  </div>
</template>

<script>
import Table from "../../TableGeneric";
import SelectGroup from "./SelectGroupForm";

export default {
  data() {
    return {
      formUsr: false,
      cliente: {
        id: null,
        enabled: true,
        usersAmount: 30
      },
    };
  },
  computed: {
    lista: {
      get() {
        return this.$store.getters.getClientes;
      },
    },
    headers: {
      get() {
        return this.$store.getters.getClienteHeaders;
      },
    },
  },
  components: {
    Table,
    SelectGroup,
  },
  methods: {
    get() {
      this.edicao = false;
      this.$store.dispatch("loadAllClientes");
      this.$store.dispatch('loadGrupos')
    },
    save(objeto) {
      this.$store.dispatch("saveCliente", objeto);
      setTimeout(() => {
        this.$store.dispatch("loadAllClientes");
      }, 500);
    },
    editar(event) {
      this.cliente = event;
      this.$vuetify.goTo(0, {
        duration: 300,
        offset: 0,
        easing: "easeInOutCubic",
      });
    },
    delet(event) {
      this.$store.dispatch("deletCliente", event);
      this.get();
    },
    clearObj() {
      Object.assign(this.$data, this.$options.data());
    },
  },
  created() {
    this.get();
  },
};
</script>

<style>
</style>